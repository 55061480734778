import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

export const parsePhone = (phone: string, country?: CountryCode): string => {
  const phoneNumber = parsePhoneNumberFromString(phone, country);
  if (phoneNumber) {
    return phoneNumber.format('NATIONAL');
  }
  return '';
};

export const omitString = (text: string, number: number): string => {
  return text.length > number ? text.slice(0, number) + '…' : text;
};

export const formatPhoneNumber = (
  phone: string,
  country?: CountryCode
): string => {
  const phoneNumber = parsePhoneNumberFromString(phone, country);

  if (phoneNumber) {
    return phoneNumber.number;
  }

  return '';
};

export const formatByteToMB = (byte: number): number => {
  return Math.ceil((byte / (1024 * 1024)) * 10) / 10;
};

export const formatToHtml = (text: string): string => {
  return text.replace(/\n/g, '<br>');
};

export const removeLastPdfExtension = (s: string): string => {
  if (s.toLowerCase().endsWith('.pdf')) {
    return s.slice(0, -4);
  }
  return s;
};
